import { Box, Typography } from "@mui/material";
import React from "react";
// import Logo from "../../../assets/AuthXLogo.png";
import bubbleImage from "../../assets/bubbleImage.png";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const NexusLoginComponent = (props) => {
  return (
    <Box
      sx={{
        width: "50vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px",
        boxSizing: "border-box",
        backgroundImage: `url(${bubbleImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography gutterBottom variant="h3" sx={{ fontWeight: "500" }}>
            Nexus
          </Typography>
          <AutoAwesomeIcon sx={{ color: "#567E00" }} />
        </Box>
        <Typography gutterBottom variant="h4" fontSize={"28px"}>
          Empowering Departmental Inquiry with Role-Based AI Solutions
        </Typography>
      </Box>
    </Box>
  );
};

export default NexusLoginComponent;
