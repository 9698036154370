import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "../../assets/VertexLogo.png";

const VertexLoginComponent = (props) => {
  return (
    <Box
      width={"50vw"}
      height={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={5}
      boxSizing={"border-box"}
      sx={{ background: "linear-gradient(32deg, #276555 0%, #0A7743 100%)" }}
      //   borderRadius={5}
    >
      <Box m={"auto"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img
            src={Logo}
            width={150}
            alt="Logo"
            style={{ filter: "drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5))" }}
          />
          <Typography fontSize={"72px"} fontFamily={"Lucida Console"}>
            Vertex
          </Typography>
        </Stack>
        <Stack mt={10}>
          <Typography variant="h2">Hello,</Typography>
          <Typography variant="h3">Welcome !</Typography>
          <Typography fontSize={20} mt={5} color={"#ffffff"}>
            Unlocking Possibilities, Empowering Journeys
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default VertexLoginComponent;
