import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "../../assets/AuthXLogo.png";

const AuthXLoginComponent = (props) => {
  return (
    <Box
      width={"50vw"}
      height={"100vh"}
      p={5}
      bgcolor={"#FCFCFC"}
      boxSizing={"border-box"}
      // borderRadius={5}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack>
        <img src={Logo} width={150} alt="Logo"></img>
      </Stack>
      <Stack mt={10}>
        <Typography variant="h2" color={"rgb(82, 119, 250)"}>
          Hello,
        </Typography>
        <Typography variant="h3" color={"rgb(82, 119, 250)"}>
          Welcome to AuthX
        </Typography>
        <Typography fontSize={20} mt={5} color={"#888888"}>
          Unlocking Possibilities, Empowering Journeys
        </Typography>
      </Stack>
    </Box>
  );
};

export default AuthXLoginComponent;
