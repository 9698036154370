import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const EmailForm = ({
  email,
  setEmail,
  formErrors,
  handleGetOTP,
  loading,
  appName,
}) => {
  const [validEmail, setValidEmail] = useState(false);
  console.log(validEmail);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Check if "@" and a domain are present in the email
    const atIndex = value.indexOf("@");
    const domainIndex = value.indexOf(".", atIndex);
    const validEmail =
      atIndex !== -1 && domainIndex !== -1 && domainIndex > atIndex + 1;

    // Update state to enable/disable the "Get OTP" button based on email validity
    setValidEmail(validEmail);
  };
  const buttonBgColor = () => {
    switch (appName) {
      case "nexus":
        return "#282828";
      case "vertex":
        return "#13DD7C";
      default:
        return "default";
    }
  };

  return (
    <Stack>
      <Typography variant="h6" fontWeight={600} m={"auto"}>
        GET STARTED HERE
      </Typography>
      <Typography m={"auto"} pt={5}>
        Login with your email
      </Typography>
      <TextField
        type="email"
        value={email}
        error={!!formErrors.email}
        helperText={formErrors.email || ""}
        FormHelperTextProps={{ style: { color: "red" } }}
        onChange={handleEmailChange}
        label={
          <span>
            Email<span style={{ color: "red" }}>*</span>
          </span>
        }
        placeholder="Enter your email"
        size="small"
        sx={{ width: 300, m: "auto", mt: 5 }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleGetOTP();
          }
        }}
      />

      <Button
        variant="contained"
        // disabled={!validEmail || !!formErrors.email || email.length === 0}
        // sx={{
        //   width: 300,
        //   m: "auto",
        //   mt: 5,
        //   bgcolor: "rgb(49, 38, 228)",
        // }}
        sx={{
          backgroundColor: buttonBgColor(),
          height: "50px",
          width: 300,
          m: "auto",
          mt: 5,
          borderRadius: "12px",
          "&:hover": {
            backgroundColor: "#282828",
          },
        }}
        onClick={handleGetOTP}
      >
        {loading ? (
          <CircularProgress size={24} sx={{ color: "white" }} />
        ) : (
          "GET OTP"
        )}
      </Button>
    </Stack>
  );
};

export default EmailForm;
